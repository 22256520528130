@import url("http://fonts.cdnfonts.com/css/helvetica-neue-9");

* {
  margin: 0;
  padding: 0;
  overflow: visible;
  font-family: Helvetica;
  font-stretch: condensed;
  text-size-adjust: inherit;
}
html,
body {
  margin: 0;
  height: 100%;
  font-family: Helvetica;
  font-stretch: condensed;
}
html {
  overflow: scroll;
  overflow-x: hidden;
}
a:-webkit-any-link {
  text-decoration: none;
  /* color: white; */
  cursor: pointer;
}
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
/* ::-webkit-scrollbar-thumb {
  background: #ff0000;
} */
.awssld__bullets button {
  background: #6c3a1f;
  height: 3px;
  width: 10px;
  border-radius: 5%;
  margin: 4px;
}

.awssld__bullets .awssld__bullets--active {
  background: #d66029;
  width: 13px;
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}
